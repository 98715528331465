@each $color, $value in $theme-colors {
  .btn {
    &-#{$color} {
      @include button-variant(
        $value,
        $value,
        $hover-background: darken($value, 6.5%),
        $hover-border: darken($value, 6.5%),
        $active-background: darken($value, 10%),
        $active-border: darken($value, 10%)
      );
      color: $white;

      &:hover,
      &:focus,
      &:active,
      &:focus-visible,
      &.active,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: $white;
      }

      &.inverse-hover {
        border-color: $white;

        &:hover,
        &:focus,
        &:active,
        &:focus-visible,
        &.active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          background-color: lighten($value, 6.5%);
          border-color: $white;
        }
      }
    }

    &-outline-#{$color} {
      @include button-outline-variant(
        $value,
        $color-hover: color-yiq($value),
        $active-background: $value,
        $active-border: $value
      );
    }

    &-circle {
      @include btn-circle;

      &-#{$color} {
        @include btn-circle;
        color: $value;

        &:hover,
        &:focus,
        &:active,
        &:focus-visible,
        &.active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          color: $value;
          border-color: $light;
          &.toogle {
            color: $light;
            border-color: $info;
          }
        }

        &:hover,
        &:focus,
        &:active,
        &:focus-visible,
        &.active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          border-color: $light;
          color: $value;
          &.toogle {
            color: $light;
            border-color: $info;
          }
        }

        &.no-shadow {
          box-shadow: none;

          &:hover,
          &:focus,
          &:active,
          &:focus-visible,
          &.active,
          &:not(:disabled):not(.disabled):active:focus,
          &:not(:disabled):not(.disabled).active:focus,
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active {
            box-shadow: none;
          }
        }
      }
    }

    &.no-border {
      border: none;

      &:hover,
      &:focus,
      &:active,
      &:focus-visible,
      &.active,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        border: none;
      }
    }
  }
}

.whatsapp-button {
  // z-index: 20;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 25px;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(85, 255, 111, 1) 0%, rgba(39, 181, 34, 1) 100%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid white;

  img {
    width: 50%;
  }
}
