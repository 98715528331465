// $grays

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

// $colors

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #ff9c07 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

// $theme-colors: This variable affects the `.bg-*` and `.text-*` classes

$primary: #2d0e7f !default; // #8649c3 !default;
$secondary: #b560ca !default; // #a55bce !default;
$success: $green !default;
$info: #7fd0ca !default;
$warning: $yellow !default;
$danger: $red !default;
$light: #a5a5af !default;
$dark: #303030 !default;
$white: #ffffff !default;
$muted: $gray-600 !default;

$primary-soft: transparentize($primary, 0.25) !default; // #614ba0
$secondary-soft: transparentize($secondary, 0.25) !default; // #c888d8
$info-soft: transparentize($info, 0.25) !default; // #a0dbd7

$primary-transparentize: transparentize($primary, 0.7);
$secondary-transparentize: transparentize($secondary, 0.6);
$info-transparentize: transparentize($info, 0.5);

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'info': $info,
    'success': $success,
    'danger': $danger,
    'warning': $warning,
    'light': $light,
    'dark': $dark,
    'white': $white,
    'muted': $muted,
    'primary-soft': $primary-soft,
    'secondary-soft': $secondary-soft,
    'info-soft': $info-soft,
  ),
  $theme-colors
);
