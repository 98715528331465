.page-header {
  display: flex;
  justify-content: center;
  padding: 0 12rem 5rem 12rem;
  margin: 0;

  @include tablet-max {
    padding: 0 8rem 5rem 8rem;
  }

  @include mobile-max {
    padding: 0 2rem 5rem 2rem;
  }
}
