.highlighted-text {
  &-primary {
    @include highlighted-text($primary-transparentize);
  }
  &-secondary {
    @include highlighted-text($secondary-transparentize);
  }
  &-info {
    @include highlighted-text($info-transparentize);
  }
}
