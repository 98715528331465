@import '~bootstrap/scss/mixins';

// Responsive

@mixin mobile-min {
  @media screen and (min-width: $md-min) {
    @content;
  }
}

@mixin mobile-max {
  @media screen and (max-width: $md-max) {
    @content;
  }
}

@mixin tablet-min {
  @media screen and (min-width: $lg-min) {
    @content;
  }
}

@mixin tablet-max {
  @media screen and (max-width: $lg-max) {
    @content;
  }
}

@mixin desktop-min {
  @media screen and (min-width: $xl-min) {
    @content;
  }
}

// General

@mixin inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin bg-full-screen {
  position: fixed;
  background: #ffffff6c;
  @include inset-0;
}

@mixin container-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
}

@mixin btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg {
    width: 20px;
    height: 20px;
  }
}

@mixin highlighted-text($color) {
  background-image: linear-gradient(transparent 60%, $color 60%);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 90% 100%;
}
