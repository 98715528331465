.about-us {
  font-size: $font-size-md;

  &__us,
  &__mission,
  &__vision,
  &__our-values {
    margin-bottom: 3rem;

    h4 {
      font-weight: 700;
      margin-bottom: 1.5rem;
      text-align: center;
      text-transform: uppercase;
    }

    .parent {
      display: flex;
      align-items: center;
    }
  }

  &__our-values {
    ul {
      padding-left: 0;
      list-style-position: inside;
    }
  }

  &__vision {
    @include mobile-min {
      div.parent {
        div:nth-child(1) {
          order: 2;
        }
        div:nth-child(2) {
          order: 1;
        }
      }
    }
  }

  &__images {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
