.language-toggle {
  border-radius: 6px;
  border: solid $primary;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $primary;

  &-item {
    font-size: $small-font-size;
    padding: 0 0.25rem;

    &:hover {
      background-color: $light;
    }
  }

  .custom-rounded-right {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .custom-rounded-left {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .active {
    font-weight: $font-weight-base;
    background-color: $primary;
    color: $white;
  }

  @include tablet-max {
    margin-top: 1rem;
  }
}
