.web-layout-footer {
  z-index: 1000;
  background: linear-gradient(to bottom right, $secondary, $primary);
  bottom: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.17);
  color: $light;
  height: 110px;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 100vw !important;
  padding: 15px;
  width: 100%;
  color: #fff;

  &__container {
    div {
      display: flex;
      align-items: center;
    }

    @include mobile-max {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
