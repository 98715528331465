.service-plan-card {
  margin-bottom: 3rem;
  height: 90%;

  .card-header {
    border: 0;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }

  .card-body {
    &__title {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 56px;
    }

    &__item {
      .list-group {
        margin-right: 1.5rem;
        margin-left: 1.5rem;

        &-item {
          border: 0;
        }
      }
    }

    &__button {
      display: flex;
      justify-content: center;

      div {
        bottom: -21px;
        display: flex;
        justify-content: center;
        position: absolute;

        button {
          color: #fff;
          text-transform: uppercase;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}
