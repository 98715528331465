@import './components/index.scss';

.web-layout-content {
  min-height: 90vh;
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}
