// https://fonts.google.com/
// Font

// // Other urls
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Play:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');

// // Default
// $font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
//   'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

// @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
$font-family-sans-serif: 'Quicksand', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

$font-family-base: $font-family-sans-serif !default;
$font-family-code: $font-family-monospace !default;

// Size

$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.85 !default;
$font-size-md: $font-size-base * 1.12 !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-xl: $font-size-base * 1.5 !default;
$small-font-size: 80% !default;

// Weight

$font-weight-lighter: lighter !default;
$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-normal !default;

// Line height

$line-height-base: 2 !default;
$line-height-sm: $line-height-base * 0.75 !default;
$line-height-lg: $line-height-base * 1.25 !default;

// Paragraph element `<p>`

$paragraph-margin-bottom: $spacer !default;

// This variable affects the `.lead` class

$lead-font-size: $font-size-md !default;
$lead-font-weight: $font-weight-bold !default;

// Highlight text with mark element `<mark>`

$mark-padding: 0.2em !default;
$mark-bg: lighten($warning, 25%) !default;

// Heading element `<h*>`: font

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// Heading element `<h*>`: margin

$headings-margin-bottom: $spacer !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: $line-height-base !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-color: null !default;

// Display

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

// Horizontal rule element `<hr />`

$hr-margin-y: $spacer !default;
$hr-border-width: $border-width !default;
$hr-border-color: $border-color !default;

// This variable affects the `.blockquote` and `.blockquote-footer` classes

$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

// General

$text-muted: $muted !default;
$dt-font-weight: $font-weight-bold !default;
$nested-kbd-font-weight: $font-weight-bold !default;
$list-inline-padding: 0.5rem !default;
