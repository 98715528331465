.carousel {
  .carousel-inner {
    .carousel-item {
      img {
        height: auto;
      }
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #000000b3;
    border-color: #00000000;
    border-radius: 25px;
    border-style: solid;
    height: 25px;
    width: 25px;
  }

  .carousel-control-prev-icon {
    border-right-width: thick;
  }

  .carousel-control-next-icon {
    border-left-width: thick;
  }
}
