.home-content-box {
  display: flex;
  justify-content: space-between;
  background-color: $primary;
  padding-top: 10rem !important;

  &__text {
    display: flex;
    align-items: center;

    h1 {
      font-size: 45px;
      font-weight: bold;
      color: $white;
    }
  }

  &__image {
    display: flex;
    justify-content: center;

    img {
      display: block;
      margin: auto;
      width: 60%;
    }
  }
}
