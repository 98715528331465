.navbar {
  z-index: 1000;
  background-color: $primary;
  margin: 0 auto !important;
  position: fixed !important;
  width: 100vw;
  padding: 1rem;

  @include tablet-max {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);

    &-toggler {
      border: none;

      svg {
        color: white;
      }
    }

    &-nav {
      padding: 2rem 1rem;
    }
  }

  &-brand {
    display: flex;
    align-items: center;

    img {
      width: 120px;
    }
  }

  &-nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @include desktop-min {
      align-items: center;
    }

    .nav-link {
      font-weight: bold;
      cursor: pointer;

      @include desktop-min {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      @include tablet-max {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.current-nav-link {
  color: $primary-soft;
  border-color: $primary-soft;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
